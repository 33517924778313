<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a
            :href="url"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            {{ storeName }}
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://originserver.co/terms.html"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            {{ $t('Terms of Service') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://originserver.co/privacy.html"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            {{ $t('Privacy Policy') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://originserver.co/refund.html"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            {{ $t('Refund Policy') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://originserver.co/contact.html"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            {{ $t('Contact Us') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://originserver.co/about.html"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            {{ $t('About Us') }}
          </a>
        </li>
      </ul>
      <div class="copyright">
        {{ $t('Powered by') }} &nbsp; <a href="https://erpstore.mrzakaria.com/" target="_blank" rel="noopener">{{ $t('ERP Store') }}</a> &copy; <i class="tim-icons icon-heart-2"></i> {{ year }}.
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  computed:{
    ...mapGetters([
      'getResources'
    ]),
    storeName() {
      return this.getResources.store;
    },
    url() {
      return this.getResources.url;
    }
  }
};
</script>
<style></style>
